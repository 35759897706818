import { MenuItem, Select } from '@material-ui/core';
import s from './numberdropdown.module.scss';

const PageDropdown = ({
  currentNumber = 1,
  onNumberUpdate = () => {},
  numberOptions = [1,2,3,4,5,6,7,8,9,10],
  label = 'Rows per page',
}) => (
  <div className={s.pageDropdownContainer}>
    <span>{label}</span>
    <Select value={currentNumber} onChange={e => onNumberUpdate(e.target.value)} >
      {numberOptions.map((num) =>
        <MenuItem value={num} key={`select-pg-${num}`}>
          {num}
        </MenuItem>)}
    </Select>
  </div>
);

export default PageDropdown;