import React from 'react';
import loadingGif from '../../assets/loader-icon.gif';
import s from './loadingicon.module.scss';

const ArrowCompoundIcon = () => {
  return (
    <div className={s.loadingIcon}>
      <img src={loadingGif} alt="device" />
    </div>
  );
}

export default ArrowCompoundIcon;