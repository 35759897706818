import { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, InputLabel } from '@material-ui/core';
import DialogModal from '../../components/DialogModal';
import PageHeader from '../../components/PageHeader';
import ButtonPrimary from '../../components/ButtonPrimary';
import SearchInput from '../../components/SearchInput';
import NumberDropdown from '../../components/NumberDropdown';
import StripedTable from '../../components/StripedTable';
import PageSelector from '../../components/PageSelector';
import LoadingIcon from '../../components/LoadingIcon';
import ArrowCompoundIcon from '../../components/ArrowCompoundIcon';
import InfoIcon from '../../components/InfoIcon';
import EditIcon from '../../assets/edit-icon.svg';
import TrashIcon from '../../assets/trash-icon.svg';
import FAKE_DATA from './mock-employee-data';
import s from './employeeslist.module.scss';

function mockApiCall(rowsPerPage, currentPage, filter) {
  return new Promise(function(resolve) {
    setTimeout(() => {
      resolve({
        body: FAKE_DATA
          .filter(e => `${e.name}|${e.email}`.toLowerCase().includes(filter.toLowerCase()))
          .slice(
            (currentPage - 1) * rowsPerPage,
            currentPage * rowsPerPage,
          ),
        total: FAKE_DATA.length,
      });
    }, 500)
  })
}

const EmployeesList = () => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [newEmployeeOpen, setNewEmployeeOpen] = useState(false);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    setLoading(true);
    mockApiCall(rowsPerPage, currentPage, searchVal)
      .then((response) => {
        setEmployees(response.body);
        setEmployeeCount(response.total)
        setLoading(false);
      });
  }, [rowsPerPage, currentPage, searchVal]);

  const handleNewEmployee = () => {
    console.log('**** New Employee');
    setNewEmployeeOpen(true);
  };

  const getTableContent = (employeeData) => employeeData.map(employee => [
    <span>{employee.name}</span>,
    <span>{employee.email.toLowerCase()}</span>,
    <span>{employee.accountCreated}</span>,
    <span>{employee.lastActive}</span>,
    <span className={s.roleSpan}>
      <div className={s[employee.role]}>{employee.role}</div>
    </span>,
    <span className={s.actionItemsContainer} >
      <img src={EditIcon} alt="devices" />
      <img src={TrashIcon} alt="devices" />
    </span>,
  ]);

  const getTableHeader = () => [
    <span>Name <ArrowCompoundIcon /></span>,
    <span>Email <ArrowCompoundIcon /></span>,
    <span>Account Created <ArrowCompoundIcon /></span>,
    <span>Last Active <ArrowCompoundIcon /></span>,
    <span>Role <ArrowCompoundIcon/><InfoIcon /></span>,
    <span>Action</span>,
  ];

  let maxPages = employeeCount / rowsPerPage;
  maxPages = maxPages > 0 ? maxPages : 1;

  return (
    <div className={s.employeesListPage}>
      <div className={s.employeesListHeader}>
        <div className={s.headerTopSection}>
          <PageHeader header={'Employee List'} subheader={`Employee Count: ${employeeCount}`} />
          <div className={s.buttonContainer}>
            <ButtonPrimary content={'Add New Employee'} handleOnClick={handleNewEmployee} />
          </div>
        </div>
        <div className={s.headerBottomSection}>
          <SearchInput placeholder={'Search employees by name, email'} onUpdate={setSearchVal} />
          <NumberDropdown
            currentNumber={rowsPerPage}
            numberOptions={[5,10,15,20]}
            onNumberUpdate={(num) => {
              setRowsPerPage(num);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
      <div className={s.employeesListBody}>
        {isLoading ?
          <LoadingIcon /> :
          <StripedTable
            headers={getTableHeader()}
            content={getTableContent(employees)}
          />
        }
      </div>
      <div className={s.employeesListFooter}>
        <PageSelector
          currentPage={currentPage}
          maxPages={maxPages}
          onPageUpdate={setCurrentPage}
        />
      </div>
      <DialogModal
        isDialogOpen={newEmployeeOpen}
        onClose={() => setNewEmployeeOpen(false)}
        confirmLabel={'Send Invitation'}
        title={'Add New Employee'}
        body={
          <div className={s.newEmployeeDialog} >
            <TextField label="NAME" fullWidth />
            <TextField label="EMAIL" fullWidth />
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={''}
              fullWidth
            >
              <MenuItem value={'Admin'}>Admin</MenuItem>
              <MenuItem value={'Viewer'}>Viewer</MenuItem>
              <MenuItem value={'Member'}>Member</MenuItem>
            </Select>
          </div>
        }
      />
    </div>
  );
};

export default EmployeesList;