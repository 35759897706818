import s from './buttonprimary.module.scss';

const ButtonPrimary = ({
  content = '',
  handleOnClick = () => {},
  type = ''
}) => {
  return (
    <button className={`${s.buttonPrimary} ${s[type]}`} onClick={handleOnClick}>
      {content}
    </button>
  );
};

export default ButtonPrimary;