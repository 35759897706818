import React, { useState } from 'react';
import Logo from '../../components/Logo';
import GIcon from '../../assets/google-logo.svg';
import landingBackgroundImg from '../../assets/landing-bkg.png';
import { MenuItem, Select } from '@material-ui/core';
import { loginUser } from '../../utils/mock-user';
import s from './landing.module.scss';

const Landing = () => {
  const [mockSignin, setMockSignin] = useState('admin');
  const handleSigininChange = (event) => {
    setMockSignin(event.target.value);
  };
  const login = () => {
    loginUser(mockSignin);
    window.location.reload();
  };

  // TODO: remove mock sign in
  return (
    <div className={s.landingPage}>
      <div className={s.logoContainer}>
        <Logo />
      </div>
      <div className={s.landingBody} >
        <div className={s.headingContainer}>
          <div className={s.headingContent}>
            <h1 className={s.headingTitle}>Welcome to Theragun admin</h1>
            <p className={s.headingDescription}>World-Leading Percussive Therapy Massage Devices</p>
            <div className={s.signinDropdown}>
              <Select
                value={mockSignin}
                onChange={handleSigininChange}
              >
                <MenuItem value={'admin'}>Mock Admin</MenuItem>
                <MenuItem value={'member'}>Mock Member</MenuItem>
                <MenuItem value={'viewer'}>Mock Viewer</MenuItem>
                <MenuItem value={'unauthorized'}>Mock Unauthorized</MenuItem>
              </Select>
            </div>
            <button className={s.googleLoginBtn} onClick={login}>
              <img src={GIcon} alt="device" />
              <span>Sign in with Google</span>
            </button>
          </div>
        </div>
        <div className={s.landingImageContainer} >
          <img src={landingBackgroundImg} alt="device" className={s.landingImage} />
        </div>
      </div>
    </div>
  );
}

export default Landing;