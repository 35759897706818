import React from 'react';
import logoImg from '../../assets/logo.svg';
import s from './logo.module.scss';

const Logo = () => {
  return (
    <img src={logoImg} alt="device" className={s.logoImage} />
  );
}

export default Logo;