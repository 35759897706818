import React from 'react';
import infoImage from '../../assets/info-icon.svg';
import s from './infoicon.module.scss';

const ArrowCompoundIcon = () => {
  return (
    <div className={s.infoIcon}>
      <img src={infoImage} alt="device" />
    </div>
  );
}

export default ArrowCompoundIcon;