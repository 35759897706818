import React from 'react';
import ButtonPrimary from '../ButtonPrimary';
import { Dialog } from '@material-ui/core';
import s from './dialogmodal.module.scss';

const DialogModal = ({
  title = '',
  body = <div></div>,
  isDialogOpen = false,
  onClose = () => {},
  onConfirm = () => {},
  confirmLabel = 'Update'
}) => {
  return (
    <Dialog className={s.modalContainer} open={isDialogOpen} onClose={onClose}>
      <h2 className={s.modalHeader}>{title}</h2>
      {body}
      <div className={s.btnContainer}>
        <ButtonPrimary type={'cancel'} content={'Cancel'} handleOnClick={onClose} />
        <ButtonPrimary content={confirmLabel} handleOnClick={onConfirm} />
      </div>
    </Dialog>
  );
}

export default DialogModal;