import React, { useState } from 'react';
import Logo from '../Logo';
import { useLocation, useHistory } from 'react-router-dom';
import { logoutUser } from '../../utils/mock-user';
import s from './sidebar.module.scss';

const Sidebar = ({ user }) => {
  let location = useLocation();
  let history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    console.log('** Sidebar Toggled: ', isCollapsed);
    setIsCollapsed(!isCollapsed);
  };
  const handleLogout = () => {
    logoutUser();
    window.location.reload();
  };
  const handleNavClick = (tabRoute) => () => {
    console.log('** Navigate To: ', tabRoute);
    history.push(tabRoute);
  };

  let visibleTabs = [{
    route: '/users',
    text: 'Users List'
  }];

  const userRole = user.getUserRole();
  if (userRole === 'admin') {
    visibleTabs = [{
      route: '/users',
      text: 'Users List'
    }, {
      route: '/employees',
      text: 'Employee List'
    }];
  };

  return (
    <div className={`${s.sidebar} ${isCollapsed ? s.collapsed : '' }`}>
      <div className={s.sidebarHeader} onClick={handleToggleCollapse}>
        <Logo />
      </div>
      <div className={s.sidebarContent}>
        <div className={s.navContainer}>
          {visibleTabs.map((tab, i) => {
            return <div
              key={`tab-item-${i}`}
              onClick={handleNavClick(tab.route)}
              className={`${s.navItem} ${tab.route === location.pathname ? s.isSelected : ''}`}
            >
              <span>{tab.text}</span>
            </div>
          })}
        </div>
      </div>
      <div className={s.sidebarFooter}>
        <div className={s.userInfo}>
          <span className={s.userPermissionLabel}>{user.getUserRole()}</span>
          <img className={s.userProfilePic} alt="device" src={user.getProfilePic()} />
          <span className={s.userName}>{user.getUserName()}</span>
        </div>
        <button className={s.logoutBtn} onClick={handleLogout}>
          <span>LOG</span><span>OUT</span>
        </button>
      </div>
    </div>
  )
};

export default Sidebar;