import FakeProfilePic from '../assets/profile.png';
const LOCAL_STORAGE_CONSTANT = 'TB-Mock-User';

const User = {
  getUser: () => {
    return {
      loggedIn: () => !!localStorage.getItem(LOCAL_STORAGE_CONSTANT),
      getUserRole: () => localStorage.getItem(LOCAL_STORAGE_CONSTANT),
      getUserName: () => 'Jack Fernando',
      getProfilePic: () => FakeProfilePic,
    };
  },
};

export const loginUser = (mockSignin) => {
  console.log('** LOGGING IN: ', mockSignin);
  window.localStorage.setItem(LOCAL_STORAGE_CONSTANT, mockSignin);
};

export const logoutUser = () => {
  console.log('** LOGGING OUT');
  window.localStorage.removeItem(LOCAL_STORAGE_CONSTANT);
};

export default User;
