import React from 'react';
import arrowUpImg from '../../assets/arrow-drop-up-icon.svg';
import arrowDownImg from '../../assets/arrow-drop-down-icon.svg';
import s from './arrowcompound.module.scss';

const ArrowCompoundIcon = () => {
  return (
    <div className={s.arrowCompound}>
      <img src={arrowUpImg} alt="device" />
      <img src={arrowDownImg} alt="device" />
    </div>
  );
}

export default ArrowCompoundIcon;