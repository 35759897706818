import s from './pageheader.module.scss';

const PageHeader = ({
  header = '',
  subheader = ''
}) => (
  <div className={s.titleContainer}>
    <h2>{header}</h2>
    <p>{subheader}</p>
  </div>
);

export default PageHeader;