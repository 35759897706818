import { useState } from 'react';
import { Dialog, TextField } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import SearchInput from '../../components/SearchInput';
import NumberDropdown from '../../components/NumberDropdown';
import StripedTable from '../../components/StripedTable';
import PageSelector from '../../components/PageSelector';
import ArrowCompoundIcon from '../../components/ArrowCompoundIcon';
import EditIcon from '../../assets/edit-icon.svg';
import TrashIcon from '../../assets/trash-icon.svg';
import s from './userslist.module.scss';

const FAKE_NAMES = ['Lina Greathouse', 'Shalonda Rinke', 'Belva Facemire', 'Pricilla Conatser', 'Jama Gartland', 'Michaela Sabbagh', 'Carlotta Mujica', 'Remedios Kowalsky', 'Evan Feltz', 'Sharmaine Albarran', 'Margene Luman', 'Cythia Pearse', 'Gena Stanhope', 'Skye Khan', 'Tera Betty', 'Nona Hankerson', 'Charlyn Montandon', 'Anthony Hunsucker', 'Todd Bruns', 'Freddy Throneberry'];

const UsersList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [newEmployeeOpen, setNewEmployeeOpen] = useState(false);
  const maxPages = 10;

  const getTableContent = () => FAKE_NAMES.map(name => [
    <span>{name}</span>,
    <span>{name.toLowerCase().replaceAll(' ', '.')}@gmail.com</span>,
    <span>Nov 4, 2020</span>,
    <span>10:15 AM, Yesterday</span>,
    <span className={s.actionItemsContainer} >
      <img src={EditIcon} alt="devices" />
      <img src={TrashIcon} alt="devices" />
    </span>,
  ]);

  const getTableHeader = () => [
    <span>Name <ArrowCompoundIcon /></span>,
    <span>Email <ArrowCompoundIcon /></span>,
    <span>Account Created <ArrowCompoundIcon /></span>,
    <span>Last Active <ArrowCompoundIcon /></span>,
    <span>Action</span>,
  ];

  return (
    <div className={s.usersListPage}>
      <div className={s.usersListHeader}>
        <div className={s.headerTopSection}>
          <PageHeader header={'User List'} subheader={`User Count: ${FAKE_NAMES.length}`} />
        </div>
        <div className={s.headerBottomSection}>
          <SearchInput placeholder={'Search users by name'} />
          <NumberDropdown currentNumber={rowsPerPage} numberOptions={[5,10,15,20]} onNumberUpdate={setRowsPerPage} />
        </div>
      </div>
      <div className={s.usersListBody}>
        <StripedTable
          headers={getTableHeader()}
          content={getTableContent()}
        />
      </div>
      <div className={s.usersListFooter}>
        <PageSelector currentPage={currentPage} maxPages={maxPages} onPageUpdate={setCurrentPage} />
      </div>
      <Dialog open={newEmployeeOpen} onClose={() => setNewEmployeeOpen(false)} >
        <div className={s.newEmployeeDialog} >
          <h2>Add New Employee</h2>
          <TextField label="NAME" fullWidth />
          <TextField label="EMAIL" fullWidth />
        </div>
      </Dialog>
    </div>
  );
};

export default UsersList;