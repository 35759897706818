import { logoutUser } from '../../utils/mock-user';
import s from './unauthorized.module.scss';

const Unauthorized = () => {
  const handleLogout = () => {
    logoutUser();
    window.location.reload();
  };

  return (
    <div className={s.unauthorizedPage}>
      <h1>Forbidden . . . . how'd you get here!??</h1>
      <button className={s.logoutButton} onClick={handleLogout}>
        Logout
      </button>
    </div>
  )
};

export default Unauthorized;