import React, { useEffect } from 'react';
import Unauthorized from './pages/Unauthorized';
import Landing from './pages/Landing';
import Sidebar from './components/Sidebar';
import UsersList from './pages/UsersList';
import EmployeesList from './pages/EmployeesList';
import './utils/mock-api';
import User from './utils/mock-user';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import s from './App.module.scss';

const AppBody = () => {
  const user = User.getUser();
  console.log('** LOADED AS: ', user.getUserRole());

  if (!user.loggedIn()) {
    return <Landing />;
  } else if (!['admin', 'member', 'viewer'].includes(user.getUserRole())) {
    return <Unauthorized />;
  }

  return (
    <div className={s.AppBody}>
      <Router>
      <Sidebar user={user} />
      <div className={s.pageContent}>
          <Switch>
            <Redirect exact from="/" to="/users" />
            <Route exact path="/users" component={UsersList} />
            <Route exact path="/employees" component={EmployeesList} />
            <Redirect from="*" to="/users" />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

function App() {
  useEffect(() => {
    document.title = 'Admin Portal';
  });

  return (
    <div className={s.App}>
      <AppBody />
    </div>
  );
}

export default App;
