import { debounce } from 'lodash';
import { Search } from '@material-ui/icons';
import s from './searchinput.module.scss';

const DEBOUNCE_WAIT = 300;

const SearchInput = ({
  placeholder = '',
  onUpdate = () => {},
}) => {
  const delayedUpdate = debounce(q => onUpdate(q), DEBOUNCE_WAIT)

  return (
    <div className={s.searchInputContainer}>
      <span className={s.searchIconContainer}><Search /></span>
      <input type="text" placeholder={placeholder} onChange={e => delayedUpdate(e.target.value)} />
    </div>
  );
};

export default SearchInput;